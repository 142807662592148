.ScannerWrapper_so778md{position:relative;height:100vh;overflow:visible;z-index:1060;}
.ScannerArea_s90nhci{position:absolute;display:var(--s90nhci-0);width:100%;height:100vh;overflow:hidden;}.ScannerArea_s90nhci video{object-fit:cover;height:100%;width:100%;background:#e2e2e2;}
.ScanZoneWrapper_sh8s3w5{display:block;position:absolute;width:100%;height:100vh;}
.ScanZoneWrapperTop_s1p5ncwl{background:#3e5c6d;opacity:0.4;width:100%;height:24vh;}
.ScanZoneWrapperSidesContainer_su3l691{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.ScanZoneWrapperSidesContainer_su3l691:before{content:"";display:block;position:absolute;width:24px;height:24px;background-size:24px 24px;background-repeat:no-repeat;background-position:0;background-image:url("../../../public/images/common/scan-zone-border.svg");left:10%;}.ScanZoneWrapperSidesContainer_su3l691:after{content:"";display:block;position:absolute;width:24px;height:24px;background-size:24px 24px;background-repeat:no-repeat;background-position:0;background-image:url("../../../public/images/common/scan-zone-border.svg");right:10%;-webkit-transform:scale(-1,1);-ms-transform:scale(-1,1);transform:scale(-1,1);}
.ScanZoneWrapperLeft_s1irvhvf{background:#3e5c6d;opacity:0.4;width:10%;height:52vh;}
.ScanZoneWrapperRight_s6g7zyn{background:#3e5c6d;opacity:0.4;width:10%;height:52vh;}
.ScanZoneWrapperBottom_s18mg14o{background:#3e5c6d;opacity:0.4;width:100%;height:24vh;}.ScanZoneWrapperBottom_s18mg14o:before{content:"";display:block;position:absolute;width:24px;height:24px;background-size:24px 24px;background-repeat:no-repeat;background-position:0;background-image:url("../../../public/images/common/scan-zone-border.svg");left:10%;top:calc(76vh - 24px);-webkit-transform:scale(1,-1);-ms-transform:scale(1,-1);transform:scale(1,-1);}.ScanZoneWrapperBottom_s18mg14o:after{content:"";display:block;position:absolute;width:24px;height:24px;background-size:24px 24px;background-repeat:no-repeat;background-position:0;background-image:url("../../../public/images/common/scan-zone-border.svg");right:10%;top:calc(76vh - 24px);-webkit-transform:scale(-1,-1);-ms-transform:scale(-1,-1);transform:scale(-1,-1);}
.Canvas_cqrea7k{position:absolute;top:0;}
.BackButton_b1j28bmf{position:absolute;top:26px;left:26px;color:#ffffff;padding-left:15px;z-index:2;font-size:1.125rem;}
.Icon_ifprdsr{display:inline-block;height:10px;width:8px;margin-right:10px;margin-bottom:1px;-webkit-filter:invert(1);filter:invert(1);-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);background-size:8px 10px;background-repeat:no-repeat;background-position:0;background-image:url("../../../public/icons/new-arrow.svg");}

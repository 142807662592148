.HeaderBase_h24fwhc{position:-webkit-sticky;position:sticky;top:0;z-index:1070;}
.HeaderWrapper_h88za3r{background-color:#3e5c6d;}
.HeaderOverlay_hpq7e35{background-color:rgba(12,70,98,0.6);position:fixed;top:0;right:0;bottom:0;left:0;z-index:110;}
.HeaderContent_h103gguk.ContentWrapper_c1b1xox{display:none;}@media (min-width:1100px){.HeaderContent_h103gguk.ContentWrapper_c1b1xox{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:20px 40px;}}
.ContentPart_c1f8va80{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.ContentPart_c1f8va80:first-child{overflow:hidden;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}.ContentPart_c1f8va80:last-child{width:100%;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;margin-left:auto;padding-left:10px;}
.SearchFieldContainer_sv73v5f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:20px;}
.ContentItem_c1gdurew{margin-right:15px;overflow:hidden;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}
.Nav_n19yzst0{-webkit-transition:all 0.4s ease-in-out;transition:all 0.4s ease-in-out;}
.NavList_nflw8tk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.NavItem_n1ok0zta{position:relative;}.NavItem_n1ok0zta:after{content:"";width:7px;height:7px;border-radius:50%;background-color:#c4c4c4;position:absolute;right:0;top:53%;-webkit-transform:translate(50%,-50%);-ms-transform:translate(50%,-50%);transform:translate(50%,-50%);}.NavItem_n1ok0zta:last-child:after{display:none;}
.NavLink_nm1ogk3{display:block;padding:5px 10px;-webkit-transition:color 0.3s;transition:color 0.3s;white-space:nowrap;text-transform:uppercase;color:#fff;font-size:24px;font-family:Oswald,Arial Narrow,Impact,sans-serif;position:relative;}.NavLink_nm1ogk3:hover,.NavLink_nm1ogk3.active{color:#e06239;}.NavLink_nm1ogk3:hover svg g,.NavLink_nm1ogk3.active svg g{fill:#e06239;}

.NavIconLink_n15exqtk.NavLink_nm1ogk3{padding-left:6px;padding-right:6px;padding-top:10px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:28px;height:28px;}
.NavLinkWrapper_niee4fr{position:relative;}
.NavMenuListWrapper_n8xgf1e{padding-top:10px;position:absolute;left:10px;display:var(--n8xgf1e-0);z-index:115;}
.NavMenuList_neslrav{position:relative;padding:10px 0;background-color:#fff;border-radius:3px;box-shadow:0 4px 4px rgb(0 0 0 / 25%);min-width:235px;}.NavMenuList_neslrav:before{content:"";height:0;width:0;border-left:7px solid transparent;border-right:7px solid transparent;border-bottom:7px solid #fff;left:30px;right:0;top:-6px;position:absolute;display:block;z-index:1;}.NavMenuList_neslrav .NavLink_nm1ogk3{color:#000;padding:10px 20px;font-size:20px;}.NavMenuList_neslrav .NavLink_nm1ogk3:hover,.NavMenuList_neslrav .NavLink_nm1ogk3:focus,.NavMenuList_neslrav .NavLink_nm1ogk3.active{background-color:#f7fafd;color:#e06239;}
.NavLinkItem_nloah0v{padding:0 5px;}
.HeaderProfile_ho9nijb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;}
.HeaderContentMobile_h1g8f21f.ContentWrapper_c1b1xox{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:13px 20px;}@media (min-width:600px){.HeaderContentMobile_h1g8f21f.ContentWrapper_c1b1xox{padding:18px 20px;}}@media (min-width:1100px){.HeaderContentMobile_h1g8f21f.ContentWrapper_c1b1xox{display:none;}}
.HeaderMobileSection_hjdzxgn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.MobileContentItem_m1mh3waj{margin-left:10px;}.MobileContentItem_m1mh3waj:first-child{margin-left:0;}.MobileContentItem_m1mh3waj:last-child{margin-left:auto;}
.HeaderMobileSearch_h14gs88z{padding:20px 0 10px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100%;}@media (min-width:600px){.HeaderMobileSearch_h14gs88z{display:none;}}
.HeaderTabletSearch_h4v9zk7.MobileContentItem_m1mh3waj{width:100%;display:none;margin-right:20px;padding:0;max-width:500px;}@media (min-width:600px){.HeaderTabletSearch_h4v9zk7.MobileContentItem_m1mh3waj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}}
.MobileBurgerIconButton_m1h61x9j{display:block;width:44px;height:44px;position:relative;background:none;border:none;cursor:pointer;margin-left:auto;width:32px;height:32px;}@media (min-width:740px){.MobileBurgerIconButton_m1h61x9j{width:44px;height:44px;}}@media (min-width:1100px){.MobileBurgerIconButton_m1h61x9j{display:none;}}
.BurgerIcon_b9tv5vz{display:block;width:32px;height:4px;background:#ffffff;-webkit-transition:all 0.4s ease-in-out;transition:all 0.4s ease-in-out;position:absolute;top:50%;left:50%;-webkit-transform:var(--b9tv5vz-0);-ms-transform:var(--b9tv5vz-0);transform:var(--b9tv5vz-0);-webkit-transform-origin:center center;-ms-transform-origin:center center;transform-origin:center center;}.BurgerIcon_b9tv5vz:before,.BurgerIcon_b9tv5vz:after{content:"";display:block;width:32px;height:4px;background:#ffffff;-webkit-transition:all 0.4s ease-in-out;transition:all 0.4s ease-in-out;position:absolute;top:50%;left:50%;-webkit-transform:var(--b9tv5vz-1);-ms-transform:var(--b9tv5vz-1);transform:var(--b9tv5vz-1);-webkit-transform-origin:center center;-ms-transform-origin:center center;transform-origin:center center;}.BurgerIcon_b9tv5vz:before{top:var(--b9tv5vz-2);}.BurgerIcon_b9tv5vz:after{top:var(--b9tv5vz-3);}
.HeaderMobileProfile_hirhhxz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}
.HeaderMobileProfileButton_h17gegqd{border:none;color:inherit;width:32px;height:32px;border-radius:50%;background:#ffffff;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:8px;cursor:pointer;position:relative;}
.WatchListCounter_w13q5gi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;width:18px;height:18px;border-radius:50%;position:absolute;top:var(--w13q5gi-0);right:var(--w13q5gi-1);background-color:#f15726;color:#fff;font-size:9px;font-weight:700;font-family:Oswald,Arial Narrow,Impact,sans-serif;line-height:18px;}
.HeaderProfileButtonContainer_hu8y12f{position:relative;}
.HeaderProfileButton_hlnzl95{border:none;color:inherit;width:32px;height:32px;border-radius:50%;background:#ffffff;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:8px;cursor:pointer;position:relative;width:46px;height:46px;margin-left:15px;}
.AppLogoLink_aj5x0oi{display:inline-block;}
.AppLogo_am1dwgo{cursor:pointer;}@media (min-width:1100px){.AppLogo_am1dwgo{width:var(--am1dwgo-1);height:auto;}}
.MobileMenu_m1pg7itb{position:absolute;top:100%;left:0;right:0;background-color:#3e5c6d;text-transform:uppercase;color:#fff;font-size:24px;font-family:Oswald,Arial Narrow,Impact,sans-serif;}
.MobileNavList_mjbmjss{padding:10px 10px 20px 10px;}
.ArrowButton_a11uinfi{position:absolute;top:0;right:0;width:50px;height:45px;-webkit-transform:rotate(var(--a11uinfi-0));-ms-transform:rotate(var(--a11uinfi-0));transform:rotate(var(--a11uinfi-0));background-color:transparent;border:none;-webkit-transition:-webkit-transform 0.4s;-webkit-transition:transform 0.4s;transition:transform 0.4s;cursor:pointer;z-index:10;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.ArrowButton_a11uinfi > svg{fill:var(--a11uinfi-1);height:13px;width:25px;}
.MobileNavSubMenu_m1ofgqg9{display:var(--m1ofgqg9-0);background-color:var(--m1ofgqg9-1);border-bottom-left-radius:3px;border-bottom-right-radius:3px;overflow:hidden;}
.MobileNavItem_m1m284fw{position:relative;}
.MobileNavLink_mg0i4eh{display:block;color:var(--mg0i4eh-0);padding:10px 15px;margin:var(--mg0i4eh-1);}.MobileNavLink_mg0i4eh.active{color:#e06239;background-color:var(--mg0i4eh-2);border-top-left-radius:3px;border-top-right-radius:3px;border-bottom-left-radius:var(--mg0i4eh-3);border-bottom-right-radius:var(--mg0i4eh-3);overflow:hidden;}
.MobileNavText_mok81wg{position:relative;padding-right:12px;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;}
.MobileNavButton_m5h7kj4{border:none;background:none;color:inherit;text-transform:inherit;margin:0;cursor:pointer;width:100%;text-align:left;padding:10px 15px;}
.ProfileMenu_p1jauet4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:230px;position:absolute;top:calc(100% + 15px);right:0;background:#fff;border-radius:4px;box-shadow:0 2px 4px rgb(0 0 0 / 25%);z-index:120;}
.ProfileMenuArrow_p1se21r9{position:absolute;height:22px;width:15px;top:-5px;right:18px;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);background:#fff;z-index:-1;}
.ProfileMenuList_p1ae7e4r{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;position:relative;padding:10px;}
.ProfileMenuLink_p6w5gzz{text-align:left;text-transform:uppercase;color:#000;font-size:20px;font-family:Oswald,Arial Narrow,Impact,sans-serif;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:10px;}.ProfileMenuLink_p6w5gzz:hover{background-color:#f7fafd;color:#f15726;-webkit-transition:all 0.3s;transition:all 0.3s;}.ProfileMenuLink_p6w5gzz.active{color:#e06239;}
.ProfileMenuButton_p1clsbr4{border:none;background:none;margin:0;cursor:pointer;text-transform:uppercase;color:#000;font-size:20px;font-family:Oswald,Arial Narrow,Impact,sans-serif;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:10px;}.ProfileMenuButton_p1clsbr4:hover{background-color:#f7fafd;color:#f15726;-webkit-transition:all 0.3s;transition:all 0.3s;}
.Container_cve65b5{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;box-sizing:border-box;margin-bottom:-15px;}@media (min-width:600px){.Container_cve65b5{margin-bottom:0;}}
.SearchContainer_s1ilvo1g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;position:relative;}
.TooltipContent_td2w5f9{color:#696969;font-size:0.750rem;}@media (min-width:740px){.TooltipContent_td2w5f9{font-size:0.875rem;}}
.SearchGridIconWrapper_s1hy8ni2{display:none;position:absolute;right:10px;top:12px;cursor:pointer;color:#a9a9a9;}@media (min-width:1100px){.SearchGridIconWrapper_s1hy8ni2{display:block;}}
.SearchWrapper_s2mo0mr{position:relative;width:100%;box-sizing:border-box;}
.SearchInput_suv3icw{display:block;width:100%;height:38px;padding:10px 40px;border:none;border-radius:4px 0 0 4px;background:#fff;box-shadow:none;box-sizing:border-box;line-height:22px;outline:none;font-size:1rem;}.SearchInput_suv3icw::-webkit-input-placeholder{color:#3d5c6d;}.SearchInput_suv3icw::-moz-placeholder{color:#3d5c6d;}.SearchInput_suv3icw:-ms-input-placeholder{color:#3d5c6d;}.SearchInput_suv3icw::placeholder{color:#3d5c6d;}.SearchInput_suv3icw:placeholder-shown{text-overflow:ellipsis;font-size:0.750rem;}@media (min-width:740px){.SearchInput_suv3icw{font-size:1rem;border-radius:2px 0 0 2px;height:100%;}.SearchInput_suv3icw:placeholder-shown{font-size:1rem;}}
.SearchIconContainer_s1hcpwbp{display:block;width:44px;height:44px;background:none;border:none;z-index:2;position:absolute;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);left:-4px;}
.SearchIcon_s870omv{display:inline-block;height:16px;width:16px;border:2px solid #3d5c6d;border-radius:50%;box-sizing:border-box;position:absolute;top:10px;left:14px;}.SearchIcon_s870omv:after{content:"";display:block;width:100%;height:2px;background:#3d5c6d;position:absolute;top:100%;left:100%;-webkit-transform:rotate(45deg) translate(-25%,-25%);-ms-transform:rotate(45deg) translate(-25%,-25%);transform:rotate(45deg) translate(-25%,-25%);-webkit-transform-origin:center left;-ms-transform-origin:center left;transform-origin:center left;}
.SearchButton_s1vo3o9r{padding:10px 15px;font-size:1rem;border:none;border-radius:0 4px 4px 0;margin-left:-2px;}@media (min-width:740px){.SearchButton_s1vo3o9r{padding:14px 20px;font-size:1.125rem;border-radius:0 2px 2px 0;margin-left:0;}}
.CameraSearch_c17u21wv{display:var(--c17u21wv-0);position:absolute;top:50%;right:8px;width:28px;height:28px;-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);transform:translate(0,-50%);background-image:url("../../../public/icons/camera.svg");background-repeat:no-repeat;background-position:center;background-color:transparent;border:none;}.CameraSearch_c17u21wv:focus{border:none;outline:transparent;}
